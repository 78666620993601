import { FieldAttributes } from "formik";
import { Checkbox as CheckboxBase } from "antd";
export default function Checkbox({ field, form, error }: FieldAttributes<any>) {
  return (
    <CheckboxBase
      data-testid="checkbox"
      name={field.name}
      checked={field.value}
      onChange={field.onChange}
    />
  );
}
