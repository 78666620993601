import { Skeleton, Row, Col, Card } from "antd";
import { times } from "lodash";

export default function AppListSkeleton() {
  return (
    <div data-testid="appListSkeleton" className="appListContainer">
      {times(4, (key) => {
        return (
          <div key={key} className="mb-20">
            <Skeleton paragraph={{ rows: 0 }} />
            <Row gutter={[16, 16]}>
              {times(4, (cardKey) => {
                return (
                  <Col key={cardKey} className="appItem">
                    <Card>
                      <Row justify="space-between">
                        <Col span={4}>
                          <Skeleton
                            paragraph={{
                              rows: 1,
                            }}
                          />
                        </Col>

                        <Col>
                          <Skeleton.Avatar />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </div>
  );
}
