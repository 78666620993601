import { gql, useQuery } from "@apollo/client";
import { Layout } from "antd";
import { useParams } from "react-router-dom";

import Sidebar from "./Sidebar";
import AppDetailsSkeleton from "./AppDetailsSkeleton";
import {
  appDetails,
  appDetailsVariables,
  appDetails_node_AppType,
} from "./types/appDetails";

export const GET_APP_DETAILS = gql`
  query appDetails($appId: ID!) {
    node(id: $appId) {
      ... on AppType {
        id
        appId
        displayName
        icon
        description
        slug
        documentation
        name
        categoryDomain {
          name
        }
      }
    }
  }
`;

export default function AppDetails() {
  const { appId } = useParams<{ appId: string }>();
  const { data, loading } = useQuery<appDetails, appDetailsVariables>(
    GET_APP_DETAILS,
    {
      variables: { appId: appId },
    }
  );

  if (loading) {
    return <AppDetailsSkeleton />;
  }
  return (
    <Layout>
      <Layout.Sider data-testid="appDetailsSidebar" width="320" theme="light">
        <Sidebar app={data?.node as appDetails_node_AppType} />
      </Layout.Sider>
      <Layout.Content
        data-testid="appDetailsContent"
        className="layoutContentContainer"
      >
        Content
      </Layout.Content>
    </Layout>
  );
}
