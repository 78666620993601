import { Typography } from "antd";
import { ErrorMessage } from "formik";

type TformikErrorMessageProps = {
  name: string;
};
export default function FormikErrorMessage(props: TformikErrorMessageProps) {
  return (
    <div data-testid={`${props.name}:error`}>
      <Typography.Text type="danger">
        {" "}
        <ErrorMessage name={props?.name!} />
      </Typography.Text>
    </div>
  );
}
