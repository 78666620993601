import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import {
  getAppInstanceInputs,
  getAppInstanceInputsVariables,
} from "./types/getAppInstanceInputs";
import AppInputSkeleton from "./AppInputSkeleton";
import Instructions from "./Instructions";
import InputForm from "./InputForm";
import "./style.scss";

export const GET_APP_INPUTS = gql`
  query getAppInstanceInputs($slug: String!) {
    appInstance(slug: $slug) {
      appInstanceId
      id
      app {
        id
        appInputs {
          appInputId
          name
          displayName
          shortDescription
          inputType
          options
          placeholder
          required
          tooltip
          verificationRegex
          regexErrorMessage
          instructionsHtml
          priority
          id
        }
      }
    }
  }
`;

export default function AppInput() {
  const { appInstanceSlug } = useParams<{ appInstanceSlug: string }>();
  const { data, loading } = useQuery<
    getAppInstanceInputs,
    getAppInstanceInputsVariables
  >(GET_APP_INPUTS, {
    variables: { slug: appInstanceSlug },
  });

  if (loading) {
    return <AppInputSkeleton />;
  }

  return (
    <Row className="heightFull">
      <Col data-testid="appInputFormCol" className="appInputCol" span={14}>
        <Typography.Title level={2}>Step 1. Provide Inputs</Typography.Title>
        <InputForm
          appInstanceId={data?.appInstance?.id}
          inputs={data?.appInstance?.app.appInputs!}
        />
      </Col>
      <Col
        data-testid="appInputInstructionCol"
        className="appInstructionCol"
        span={10}
      >
        <Typography.Title className="p-15" level={2}>
          Instructions
        </Typography.Title>
        <Instructions inputs={data?.appInstance?.app.appInputs!} />
      </Col>
    </Row>
  );
}
