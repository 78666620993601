import { FieldAttributes } from "formik";
import { Input } from "antd";

export default function InputBox({
  field,
  form,
  error,
  placeholder,
}: FieldAttributes<any>) {
  return (
    <Input
      data-testid={`${field.name}:inputbox`}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      placeholder={placeholder}
    />
  );
}
