import { gql, useQuery } from "@apollo/client";
import { Col, Row, Space, Typography } from "antd";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import decorateAppName from "../../utils/decorateAppName";
import AppIcon from "../widgets/AppIcon";
import {
  getAppInstanceInfo,
  getAppInstanceInfoVariables,
} from "./types/getAppInstanceInfo";
import SidebarSkeleton from "./SidebarSkeleton";

type TsidebarProps = {
  currentStep: Esteps;
  setCurrentStep: (step: Esteps) => void;
};

export enum Esteps {
  input = "input",
  collectData = "collectData",
  downloadData = "downloadData",
  schedule = "schedule",
}

export const GET_APP_INSTANCE_INFO = gql`
  query getAppInstanceInfo($slug: String!) {
    appInstance(slug: $slug) {
      id
      previousState
      app {
        id
        name
        icon
        categoryDomain {
          name
        }
      }
    }
  }
`;

export default function Sidebar({
  currentStep,
  setCurrentStep,
}: TsidebarProps) {
  const { appInstanceSlug } = useParams<{ appInstanceSlug: string }>();
  const { data, loading } = useQuery<
    getAppInstanceInfo,
    getAppInstanceInfoVariables
  >(GET_APP_INSTANCE_INFO, {
    variables: {
      slug: appInstanceSlug,
    },
  });
  if (loading) {
    return <SidebarSkeleton />;
  }
  let appInstance = data?.appInstance;
  return (
    <Space
      direction="vertical"
      size="large"
      className="sidebarContainer px-20 widthFull"
    >
      <Row justify="space-between">
        <Col data-testid="appName" span={14}>
          {appInstance &&
            parse(
              decorateAppName(
                appInstance?.app.name!,
                appInstance?.app.categoryDomain.name!
              )
            )}
        </Col>

        <Col>
          <AppIcon
            src={appInstance?.app?.icon!}
            appId={appInstance?.app?.id!}
          />
        </Col>
      </Row>
      <Space direction="vertical" size="large">
        <div
          onClick={() => setCurrentStep(Esteps.input)}
          className={
            currentStep === Esteps.input
              ? "selectedInputWizardStep"
              : "inputWizardStep"
          }
        >
          <Typography.Title level={5}>Step1. Provide Inputs</Typography.Title>
          <Typography.Text>
            Provide some input about what you want and from where do you want to
            get the data.
          </Typography.Text>
        </div>
        <div
          onClick={() => setCurrentStep(Esteps.collectData)}
          className={
            currentStep === Esteps.collectData
              ? "selectedInputWizardStep"
              : "inputWizardStep"
          }
        >
          <Typography.Title level={5}>Step2. Collect Data</Typography.Title>
          <Typography.Text>
            We will collect data for you according to your input.
          </Typography.Text>
        </div>
        <div
          data-testid="appInputWizardStep3"
          onClick={() => setCurrentStep(Esteps.downloadData)}
          className={
            currentStep === Esteps.downloadData
              ? "selectedInputWizardStep"
              : "inputWizardStep"
          }
        >
          <Typography.Title level={5}>Step3. Download Data</Typography.Title>
          <Typography.Text>
            You can download the data in any of the format you want.
          </Typography.Text>
        </div>
        <div
          onClick={() => setCurrentStep(Esteps.schedule)}
          className={
            currentStep === Esteps.schedule
              ? "selectedInputWizardStep"
              : "inputWizardStep"
          }
        >
          <Typography.Title level={5}>Step4. Schedules</Typography.Title>
          <Typography.Text>
            You could collect this data periodically and get it delivered
          </Typography.Text>
        </div>
      </Space>
    </Space>
  );
}
