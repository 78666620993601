import { FieldAttributes } from "formik";
import { Radio } from "antd";
import { useEffect, useState } from "react";

interface Ioptions {
  label: string;
  value: string;
}

export default function RadioButton({
  field,
  form,
  error,
  options,
}: FieldAttributes<any>) {
  const [optionList, handleOptionList] = useState<Ioptions[]>([]);

  useEffect(() => {
    let optionsJson = JSON.parse(options);
    handleOptionList(optionsJson);
  }, [options]);

  return (
    <Radio.Group
      data-testid="radioGroup"
      value={field.value}
      onChange={field.onChange}
      name={field.name}
    >
      {optionList.map((option, key) => {
        return (
          <Radio
            data-testid={`radioButton:${option.label}`}
            key={key}
            value={option.value}
          >
            {option.label}
          </Radio>
        );
      })}
    </Radio.Group>
  );
}
