import { Skeleton, Layout, Col, Row } from "antd";

export default function AppDetailsSkeleton() {
  return (
    <Layout data-testid="appDetailsSkeleton">
      <Layout.Sider width="320" theme="light">
        <div className="sidebarContainer px-10">
          <Row justify="space-between">
            <Col span={4}>
              <Skeleton
                paragraph={{
                  rows: 1,
                  width: 100,
                }}
              />
            </Col>
            <Col>
              <Skeleton.Avatar />
            </Col>
          </Row>
        </div>
      </Layout.Sider>
      <Layout.Content className="layoutContentContainer">
        <Skeleton
          className="px-10"
          paragraph={{
            rows: 10,
          }}
        />
      </Layout.Content>
    </Layout>
  );
}
