import { Layout } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";

import Header from "../header/Header";
import AppStore from "../app-store/AppStore";
import AppDetails from "../app-details/AppDetails";
import AppInputWizard from "../app-input-wizard/AppInputWizard";
import "./style.scss";

export default function Container() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header className="layoutHeader">
        <Header />
      </Layout.Header>
      <Layout className="layoutContainer">
        <Switch>
          <Route path="/apps" exact>
            <AppStore />
          </Route>
          <Route path="/app/:appId">
            <AppDetails />
          </Route>
          <Route path="/appinput/:appInstanceSlug" exact>
            <AppInputWizard />
          </Route>
          <Route path="/" component={() => <Redirect to="/apps" />} />
        </Switch>
      </Layout>
    </Layout>
  );
}
