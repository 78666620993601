import { Button, Form } from "antd";
import { Field, Formik } from "formik";
import { isEmpty } from "lodash";
import { gql, useMutation } from "@apollo/client";

import { AppInputInputType } from "../../../types/globalTypes";
import { getAppInstanceInputs_appInstance_app_appInputs } from "./types/getAppInstanceInputs";
import Textarea from "./fields/Textarea";
import InputBox from "./fields/InputBox";
import SelectBox from "./fields/SelectBox";
import MultiSelectBox from "./fields/MultiSelectBox";
import Checkbox from "./fields/Checkbox";
import RadioButton from "./fields/RadioButton";
import FormikErrorMessage from "../../widgets/FormikErrorMessage";
import {
  createAppInputInstance,
  createAppInputInstanceVariables,
} from "./types/createAppInputInstance";
import { errorField } from "../../../reactive-variables/ErrorField";

type TinputFormProps = {
  inputs: (getAppInstanceInputs_appInstance_app_appInputs | null)[] | null;
  appInstanceId: string | undefined;
};

type TformFieldProps = {
  fieldInfo: getAppInstanceInputs_appInstance_app_appInputs;
  error: string;
};

export const CREATE_APP_INPUT_INSTANCE = gql`
  mutation createAppInputInstance(
    $appInstanceId: String
    $inputName: String
    $inputs: [InputObject]
  ) {
    saveInputsAndStartApp(
      appInstanceId: $appInstanceId
      inputName: $inputName
      inputs: $inputs
    ) {
      ok
    }
  }
`;

function FormField({ fieldInfo, error }: TformFieldProps) {
  function getFieldComponent(fieldType: AppInputInputType) {
    switch (fieldType) {
      case AppInputInputType.TEXTAREA:
        return Textarea;
      case AppInputInputType.SELECT:
        return SelectBox;
      case AppInputInputType.MULTISELECT:
        return MultiSelectBox;
      case AppInputInputType.RADIO:
        return RadioButton;
      case AppInputInputType.CHECKBOX:
        return Checkbox;
      default:
        return InputBox;
    }
  }

  function validateField(value: any) {
    if (fieldInfo.required && isEmpty(value)) {
      return "This field is required";
    }
    if (
      value &&
      !isEmpty(fieldInfo.verificationRegex) &&
      (fieldInfo.inputType === AppInputInputType.INPUT ||
        fieldInfo.inputType === AppInputInputType.TEXTAREA)
    ) {
      let regex = new RegExp(fieldInfo.verificationRegex);
      let valueList = value.trim().split("\n");
      for (let index = 0; index < valueList.length; index++) {
        if (!regex.test(valueList[index])) {
          if (isEmpty(errorField())) errorField([`${fieldInfo.id}`]);
          return fieldInfo.regexErrorMessage;
        }
      }
    }
    errorField([]);
    return undefined;
  }

  return (
    <Field
      id={fieldInfo?.id}
      name={fieldInfo?.name}
      component={getFieldComponent(fieldInfo?.inputType!)}
      options={fieldInfo?.options}
      placeholder={fieldInfo?.placeholder}
      isMulti={
        fieldInfo?.inputType === AppInputInputType.MULTISELECT ? true : false
      }
      validate={validateField}
      error={error}
    />
  );
}

export default function InputForm({ inputs, appInstanceId }: TinputFormProps) {
  const [createAppInputInstance, { loading }] = useMutation<
    createAppInputInstance,
    createAppInputInstanceVariables
  >(CREATE_APP_INPUT_INSTANCE);

  function getInitialValues() {
    let initialValues = {};
    inputs?.forEach((input) => {
      (initialValues as any)[input?.name!] = "";
    });
    (initialValues as any)["inputInstanceName"] = "";
    return initialValues;
  }

  function handleSubmit(values: any) {
    let inputInstanceName = values.inputInstanceName;
    delete values.inputInstanceName;
    let inputs = Object.keys(values).map((key) => ({
      name: key,
      value: values[key],
    }));
    createAppInputInstance({
      variables: {
        appInstanceId: appInstanceId!,
        inputs: inputs,
        inputName: inputInstanceName,
      },
    });
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, handleReset, handleSubmit }) => {
        return (
          <Form onReset={handleReset} onFinish={handleSubmit} layout="vertical">
            {inputs?.map((inputElement, key) => {
              return (
                <Form.Item key={key} label={inputElement?.shortDescription}>
                  <FormField
                    fieldInfo={inputElement!}
                    error={(errors as any)[inputElement?.name!]}
                  />
                  <FormikErrorMessage name={inputElement?.name!} />
                </Form.Item>
              );
            })}
            <Form.Item label="Would you like to name this input configuration for future runs?">
              <Field
                validate={(value: any) => {
                  if (isEmpty(value)) {
                    return "This field is required";
                  }
                  return undefined;
                }}
                name="inputInstanceName"
                component={InputBox}
                placeholder={"Input instance name"}
              />
              <FormikErrorMessage name="inputInstanceName" />
            </Form.Item>

            <Button
              data-testid="inputFormSubmit"
              disabled={isSubmitting || loading}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              {isSubmitting || loading ? "Submitting" : "Submit"}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
