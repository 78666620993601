import { Typography } from "antd";
import { Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

export default function AppSearch() {
  const history = useHistory();
  const location = useLocation();
  const [searchTerm, handleSearchTerm] = useState("");

  useEffect(() => {
    let query = queryString.parse(location.search);
    handleSearchTerm(query.search ? (query.search as string) : "");
  }, [location.search]);

  function updateSearchTermToUrl(term: string) {
    let query: { search?: string } = {};
    if (!isEmpty(term)) {
      query["search"] = term;
    }
    history.push({
      pathname: "/apps",
      search: queryString.stringify(Object.assign({}, query)),
    });
  }

  return (
    <>
      <Typography.Title level={2}>
        {" "}
        Explore our apps to collect public data from the internet to
        spreadsheets
      </Typography.Title>
      <Input.Search
        data-testid="searchbox"
        onChange={(e) => {
          e.preventDefault();
          handleSearchTerm(e.target.value);
        }}
        value={searchTerm}
        onSearch={(e) => {
          updateSearchTermToUrl(e);
        }}
        className="searchBox"
        enterButton="Search"
        placeholder="Search For Apps"
      />
    </>
  );
}
