import { Card, Col, Row, Typography } from "antd";
import parse from "html-react-parser";

import { TcleanedAppList } from "./AppStore";
import AppListSkeleton from "./AppListSkeleton";
import decorateAppName from "../../utils/decorateAppName";
import { Link } from "react-router-dom";

type TappListProps = {
  appList: TcleanedAppList;
  loading: boolean;
};

export default function AppList(props: TappListProps) {
  if (props.loading) {
    return <AppListSkeleton />;
  }
  return (
    <div data-testid="appList" className="appListContainer">
      {props.appList?.map((appListItem, appListKey) => {
        return (
          <div className="mb-20" key={appListKey}>
            <Typography.Text>{appListItem.node.name}</Typography.Text>
            {appListItem.node.categoryDomains?.map(
              (appCategoryDomain, categoryDomainKey) => {
                return (
                  <div className="mb-20" key={categoryDomainKey}>
                    <Typography.Title
                      data-testid={`${appCategoryDomain?.node?.name}categoryDomain`}
                      className="appDomain"
                      level={3}
                    >
                      {appCategoryDomain?.node?.name}
                    </Typography.Title>
                    <Row gutter={[16, 16]}>
                      {appCategoryDomain?.node?.apps?.edges.map((app) => {
                        return (
                          <Col className="appItem" key={app?.node?.id}>
                            <Link
                              data-testid={`appItem:${app?.node?.id}`}
                              to={`/app/${app?.node?.id}/appoverview`}
                            >
                              <Card>
                                <Row justify="space-between">
                                  <Col
                                    data-testid={`appName:${app?.node?.id}`}
                                    span={16}
                                  >
                                    {parse(
                                      decorateAppName(
                                        app?.node?.name!,
                                        appCategoryDomain.node?.name!
                                      )
                                    )}
                                  </Col>

                                  <Col>
                                    <img
                                      className="appIcon"
                                      src={app?.node?.icon!}
                                      alt={app?.node?.displayName}
                                      data-testid={`appIcon:${app?.node?.id}`}
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
}
