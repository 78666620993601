import { Skeleton } from "antd";
import { times } from "lodash";

export default function CategoryFilterSkeleton() {
  return (
    <div data-testid="categorySkeleton" className="sidebarContainer pl-10">
      {times(8, (key) => {
        return (
          <div key={key} className="mb-20">
            <Skeleton paragraph={{ rows: 0 }} />
          </div>
        );
      })}
    </div>
  );
}
