import { Input } from "antd";
import { FieldAttributes } from "formik";

export default function Textarea({
  field,
  form,
  placeholder,
}: FieldAttributes<any>) {
  return (
    <Input.TextArea
      data-testid="textarea"
      rows={6}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      placeholder={placeholder}
    />
  );
}
