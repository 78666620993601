import { gql, useMutation } from "@apollo/client";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import {
  createAppInstance,
  createAppInstanceVariables,
} from "./types/createAppInstance";

export const CREATE_APP_INSTANCE = gql`
  mutation createAppInstance($appId: String) {
    createAppInstance(appId: $appId) {
      appInstance {
        slug
      }
    }
  }
`;

type TcollectDataProps = {
  appId: string | null | undefined;
};

export default function CollectData({ appId }: TcollectDataProps) {
  const history = useHistory();
  const [callCreateAppInstance, { loading }] = useMutation<
    createAppInstance,
    createAppInstanceVariables
  >(CREATE_APP_INSTANCE, {
    variables: { appId: appId },
    onCompleted: (data) => {
      history.push({
        pathname: `/appinput/${data.createAppInstance?.appInstance?.slug}`,
      });
    },
  });
  return (
    <Button
      className="mt-10"
      onClick={() => callCreateAppInstance()}
      loading={loading}
      disabled={loading}
      type="primary"
    >
      Collect Data
    </Button>
  );
}
