import { Col, Menu, Row } from "antd";
import { Link, useHistory } from "react-router-dom";

import CloudLogo from "../../images/cloudLogo.png";
import "./style.scss";

export default function Header() {
  const history = useHistory();
  return (
    <Row gutter={[0, 10]}>
      <Col>
        <Link to="/" className="cloudLogo">
          <img src={CloudLogo} alt="logo" />
        </Link>
      </Col>

      <Col>
        <Menu className="headerMenu" theme="light" mode="horizontal">
          <Menu.Item onClick={() => history.push("/apps")} key="appStore">
            App Store
          </Menu.Item>
          <Menu.Item onClick={() => history.push("/myapps")} key="myApps">
            My Apps
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
}
