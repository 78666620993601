import { gql, useLazyQuery } from "@apollo/client";
import { Layout } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import CategoryFilter from "./CategoryFilter";
import AppSearch from "./AppSearch";
import AppList from "./AppList";
import {
  getAppList,
  getAppList_categories_edges_node_categoryDomains_edges,
} from "./types/getAppList";

import "./style.scss";

export type TcleanedAppList =
  | {
      node: {
        categoryDomains:
          | (getAppList_categories_edges_node_categoryDomains_edges | null)[]
          | undefined;
        __typename?: "CategoryType" | undefined;
        id?: string | undefined;
        name?: string | undefined;
      };
      __typename?: "CategoryTypeEdge" | undefined;
    }[]
  | undefined;

export const GET_APP_LIST = gql`
  query getAppList($search: String) {
    categories {
      edges {
        node {
          id
          name
          categoryDomains {
            edges {
              node {
                id
                name
                apps(search: $search) {
                  edges {
                    node {
                      id
                      displayName
                      name
                      icon
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function AppStore() {
  const location = useLocation();
  const [cleanedAppList, setCleanedAppList] = useState<TcleanedAppList>([]);
  const [fetchAppsList, { loading }] = useLazyQuery<getAppList>(GET_APP_LIST, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      function getCategoryDomainsAppCount(categoryDomainEdge: any) {
        let count = 0;
        categoryDomainEdge.forEach((edge: any) => {
          let domainAppCount = edge?.node?.apps?.edges.length;
          count = count + (domainAppCount ? domainAppCount : 0);
        });
        return count;
      }
      let cleanedAppList = data.categories?.edges
        .filter((categoryEdge: any) => {
          return (
            categoryEdge?.node?.categoryDomains?.edges.length !== 0 &&
            getCategoryDomainsAppCount(
              categoryEdge?.node?.categoryDomains?.edges!
            ) > 0
          );
        })
        .map((edge: any) => {
          return {
            ...edge,
            node: {
              ...edge?.node,
              categoryDomains: edge?.node?.categoryDomains?.edges.filter(
                (categoryDomainEdge: any) =>
                  categoryDomainEdge?.node?.apps?.edges.length !== 0
              ),
            },
          };
        });
      setCleanedAppList(cleanedAppList ? cleanedAppList : []);
    },
  });

  useEffect(() => {
    let query = queryString.parse(location.search);
    fetchAppsList({
      variables: {
        category: query.category as string,
        search: query.search as string,
      },
    });
  }, [fetchAppsList, location.search]);

  return (
    <Layout>
      <Layout.Sider data-testid="categoryFilter" width="320" theme="light">
        <CategoryFilter />
      </Layout.Sider>
      <Layout.Content
        data-testid="appListSection"
        className="layoutContentContainer"
      >
        <div className="appListSectionContainer">
          <AppSearch />
          <AppList loading={loading} appList={cleanedAppList} />
        </div>
      </Layout.Content>
    </Layout>
  );
}
