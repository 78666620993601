import { Skeleton } from "antd";
import { useState } from "react";

type TappIconProps = {
  src: string;
  appId: string;
};

export default function AppIcon({ src, appId }: TappIconProps) {
  const [imageLoaded, setImageLoader] = useState(false);
  return (
    <>
      {!imageLoaded && <Skeleton.Avatar />}
      <img
        alt="appIcon"
        className="appIcon"
        src={src}
        onLoadCapture={() => {
          setImageLoader(true);
        }}
        data-testid={`appIcon:${appId}`}
      />
    </>
  );
}
