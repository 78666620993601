import { gql, useQuery } from "@apollo/client";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import CategoryFilterSkeleton from "./CategoryFilterSkeleton";
import { getCategories } from "./types/getCategories";

export const GET_CATEGORY_LIST = gql`
  query getCategories {
    categories {
      edges {
        node {
          id
          name
          categoryDomains {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function CategoryFilter() {
  const { loading, data } = useQuery<getCategories>(GET_CATEGORY_LIST);
  const history = useHistory();
  const location = useLocation();
  const [activeCategory, handleActiveCategory] = useState("");

  useEffect(() => {
    let query = queryString.parse(location.search);
    handleActiveCategory(query?.category ? (query?.category as string) : "");
  }, [location.search]);

  function applyCategory(category: string) {
    history.push({
      pathname: "/apps",
      search: `?category=${category}`,
    });
  }

  if (loading) {
    return <CategoryFilterSkeleton />;
  }

  return (
    <div data-testid="categoryFilterList" className="sidebarContainer">
      <Menu>
        {data?.categories?.edges.map((category) => {
          if (category?.node?.categoryDomains?.edges.length !== 0)
            return (
              <Menu.ItemGroup
                key={category?.node?.id}
                title={category?.node?.name}
              >
                {category?.node?.categoryDomains?.edges.map((domains) => {
                  return (
                    <Menu.Item
                      className={
                        activeCategory === domains?.node?.name
                          ? "ifCategorySelected"
                          : ""
                      }
                      onClick={() => {
                        applyCategory(domains?.node?.name!);
                      }}
                      key={domains?.node?.id}
                    >
                      {domains?.node?.name}
                    </Menu.Item>
                  );
                })}
              </Menu.ItemGroup>
            );
          return "";
        })}
      </Menu>
    </div>
  );
}
