import { lowerCase } from "lodash";

export const appColorMatcher = {
  walmart: "#1B74CE",
  amazon: "#EE9A13",
  google: "#31AA52",
  instagram: "#C23584",
  glassdoor: "#0CAA41",
  twitter: "#1EA1F0",
  linkedIn: "#0077B5",
  realtor: "#d92228",
  indeed: "#2061EB",
  redfin: "#A02021",
  target: "#CC000",
  yelp: "#BA2026",
  trulia: "#01ADBC",
  zillow: "#0069FE",
  yellow_pages: "#EBB708",
};

export default function decorateAppName(appName: string, appSubDomain: string) {
  return appName.replace(appSubDomain, (matchedValue: string) => {
    return `<p style='color: ${
      (appColorMatcher as any)[lowerCase(appSubDomain)]
    };margin:0px;margin-bottom:1px;'>${matchedValue}</p>`;
  });
}
