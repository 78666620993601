import { FieldAttributes } from "formik";
import { Select } from "antd";
import { useEffect, useState } from "react";

interface Ioptions {
  label: string;
  value: string;
}

export default function MultiSelectBox({
  field,
  form,
  ...props
}: FieldAttributes<any>) {
  const [options, handleOptions] = useState<Ioptions[]>([]);

  useEffect(() => {
    let optionsJson = JSON.parse(props.options);
    handleOptions(optionsJson);
  }, [props.options]);

  function handleChange(selectedValue: any) {
    if (selectedValue) {
      form.setFieldValue(field.name, selectedValue.join(","));
    }
  }

  function getValue() {
    if (field.value) {
      let valueList = field.value.split(",");
      return valueList;
    }
    return [];
  }

  return (
    <Select
      data-testid="multiSelect"
      mode="multiple"
      allowClear
      style={{ width: "100%" }}
      placeholder={props.placeholder}
      onChange={handleChange}
      value={getValue()}
    >
      {options.map((option, key) => {
        return (
          <Select.Option
            data-testid={`multiselect-option:${option.label}`}
            key={key}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}
