import { FieldAttributes } from "formik";
import { Select } from "antd";
import { useEffect, useState } from "react";

interface Ioptions {
  label: string;
  value: string;
}

export default function SelectBox({
  field,
  options,
  placeholder,
  form,
}: FieldAttributes<any>) {
  const [optionsList, handleOptionList] = useState<Ioptions[]>([]);

  useEffect(() => {
    let optionsJson = JSON.parse(options);
    handleOptionList(optionsJson);
  }, [options]);

  function handleChange(selectedValue: any) {
    if (selectedValue) {
      form.setFieldValue(field.name, selectedValue);
    }
  }

  return (
    <Select
      data-testid="select"
      allowClear
      style={{ width: "100%" }}
      placeholder={placeholder}
      onChange={handleChange}
      value={field.value}
    >
      {optionsList.map((option, key) => {
        return (
          <Select.Option
            data-testid={`select-option:${option.label}`}
            key={key}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}
