import { Col, Menu, Row, Space } from "antd";
import parse from "html-react-parser";

import AppIcon from "../widgets/AppIcon";
import CollectData from "./CollectData";
import { appDetails_node_AppType } from "./types/appDetails";
import decorateAppName from "../../utils/decorateAppName";

type TsidebarProps = {
  app: appDetails_node_AppType | undefined;
};
export default function Sidebar({ app }: TsidebarProps) {
  return (
    <div className="sidebarContainer px-10">
      <Row justify="space-between">
        <Col data-testid="appName" span={14}>
          {app && parse(decorateAppName(app.name!, app.categoryDomain.name!))}
        </Col>

        <Col>
          <AppIcon src={app?.icon!} appId={app?.id!} />
        </Col>
      </Row>
      <Space className="widthFull" direction="vertical" size="large">
        <CollectData appId={app?.id} />
        <Menu defaultSelectedKeys={["appOverview"]}>
          <Menu.Item data-testid="appOverviewMenuItem" key="appOverview">
            App Overview
          </Menu.Item>
          <Menu.Item data-testid="appDocumentationMenuItem" key="documentaion">
            Documentation
          </Menu.Item>
          <Menu.Item data-testid="appSolutionsMenuItem" key="solutions">
            Solutions using this app
          </Menu.Item>
          <Menu.Item data-testid="appNeedHelpMenuItem" key="help">
            Need Help
          </Menu.Item>
        </Menu>
      </Space>
    </div>
  );
}
