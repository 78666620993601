import { Layout, Row, Col, Skeleton } from "antd";

export default function SidebarSkeleton() {
  return (
    <Layout data-testid="appInputWizardSidebarSkeleton">
      <Layout.Sider width="320" theme="light">
        <div className="sidebarContainer px-20">
          <Row justify="space-between">
            <Col span={4}>
              <Skeleton
                paragraph={{
                  rows: 1,
                  width: 100,
                }}
              />
            </Col>
            <Col>
              <Skeleton.Avatar />
            </Col>
          </Row>
        </div>
      </Layout.Sider>
    </Layout>
  );
}
