import { Skeleton, Layout } from "antd";

export default function AppInputSkeleton() {
  return (
    <Layout data-testid="appInputFormSkeleton">
      <Layout.Content className="layoutContentContainer px-10">
        <Skeleton
          paragraph={{
            rows: 0,
          }}
        />
        <Skeleton.Input style={{ width: "350px" }} />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
        />
        <Skeleton.Input style={{ width: "350px" }} />
      </Layout.Content>
    </Layout>
  );
}
