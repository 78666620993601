import { Layout } from "antd";
import { useState } from "react";

import AppInput from "./app-input/AppInput";
import Sidebar, { Esteps } from "./Sidebar";
import "./style.scss";

export default function AppInputWizard() {
  const [currentStep, setCurrentStep] = useState(Esteps.input);

  return (
    <Layout>
      <Layout.Sider
        data-testid="appInputWizardLeftSidebar"
        width="320"
        theme="light"
      >
        <Sidebar setCurrentStep={setCurrentStep} currentStep={currentStep} />
      </Layout.Sider>
      <Layout.Content
        data-testid="appInputWizardLeftContent"
        className="layoutContentContainer"
      >
        <AppInput />
      </Layout.Content>
    </Layout>
  );
}
