import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import cookie from "react-cookies";
import { setContext } from "@apollo/client/link/context";

import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: "/graphql/",
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  const token = cookie.load("csrftoken");
  return {
    headers: {
      ...headers,
      "X-CSRFToken": token,
    },
  };
});

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError && (networkError as any).statusCode === 403) {
    window.location.replace(window.location.origin + "/accounts/login/");
  }
});

export const apolloClient = new ApolloClient({
  link: from([link, authLink, httpLink]),
  cache: new InMemoryCache(),
});
