import parse from "html-react-parser";

import { getAppInstanceInputs_appInstance_app_appInputs } from "./types/getAppInstanceInputs";
import { errorField } from "../../../reactive-variables/ErrorField";
import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

export type TinstructionsProps = {
  inputs: (getAppInstanceInputs_appInstance_app_appInputs | null)[] | null;
};

export default function Intructions(props: TinstructionsProps) {
  const errorFieldId = useReactiveVar(errorField);

  useEffect(() => {
    if (errorFieldId) {
      let instrctionElement = document.getElementById(
        `instruction:${errorFieldId[0]}`
      );
      if (instrctionElement) {
        instrctionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errorFieldId]);

  return (
    <>
      {props.inputs?.map((input, key) => {
        return (
          <div
            data-testid={`instruction:${input?.id}`}
            id={`instruction:${input?.id}`}
            className={
              errorFieldId && input?.id === errorFieldId[0]
                ? "inputInstructionWithError"
                : "inputInstruction"
            }
            key={key}
          >
            {parse(input?.instructionsHtml!)}
          </div>
        );
      })}
    </>
  );
}
