/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AppInputInputType {
  CHECKBOX = "CHECKBOX",
  INPUT = "INPUT",
  MULTISELECT = "MULTISELECT",
  RADIO = "RADIO",
  SELECT = "SELECT",
  TEXTAREA = "TEXTAREA",
}

export interface InputObject {
  appInputId?: string | null;
  value?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
